import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import { ModalWrapper, ModalContainer } from "../../Dashboard/Dashboard.style";
import { Header, AddModulesContainer } from "../Modal.style";
import DropzoneComponent from "../../Dropzone/DropzoneComponent";
import { Input } from "../../Input/InputComponent";
import { ButtonWrapper, Button } from "../../Dashboard/Dashboard.style";
import { Module } from "../../../Api/api";
import { useAuth } from "../../../Auth/Auth";
import DocumentLoading from "../../Loading/ExportLoader";
import Uploader from "./Uploader";
import JSZip from "jszip";

const getFileType = (name) => {
  const file = {
    ext: name.split(".")[name.split(".").length - 1],
  };
  if (["jpg", "jpeg", "png", "gif"].includes(file.ext))
    file.type = `image/${file.ext}`;
  if (["mkv", "mp4", "m4v", "swf", "avi"].includes(file.ext))
    file.type = `video/${file.ext}`;
  if (["mp3"].includes(file.ext)) file.type = `audio/${file.ext}`;
  if (["otf", "woff", "woff2", "tff"].includes(file.ext))
    file.type = `font/${file.ext}`;
  if (["html", "css", "js"].includes(file.ext)) file.type = `text/${file.ext}`;
  console.log(file);
  if (!file.type) file.type = `application/${file.ext}`;
  console.log(file);
  return file;
};

const ModuleUploadModal = (props) => {
  const { readCookie, DisplaySnackbar } = useAuth();
  const { setModal, activeId, setAllModule, allModule, lang } = props;
  const [loading, setLoading] = useState(false);
  const [uploader, setUploader] = useState(false);
  //Module Data States
  const [moduleName, setModuleName] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [video, setVideo] = useState(null);
  const [file, setFile] = useState(null);
  const [audio, setAudio] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  const files = useRef({});

  //Close Modal
  const closeModal = () => {
    if (uploader) {
      DisplaySnackbar("Cannot close modal. Uploading is in progress!", "error");
      return;
    }
    files.current = {}; //clear files before closing modal
    setModal(false);
  };

  /**
   * create empty modules in database
   */
  const createModule = async () => {
    if (!moduleName) {
      DisplaySnackbar("Please provide module name!", "error");
      return;
    }
    setLoading(true);

    try {
      const res = await axios.post(
        Module.addModulesV2,
        { name: moduleName, topicId: activeId, lang: lang },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
          validateStatus: () => true,
        }
      );

      if (res.data.status !== "success") throw res;

      //setAllModule([...allModule, res.data.data.module]);
      setModuleId(res.data.data.module._id);
    } catch (err) {
      setLoading(false);
      DisplaySnackbar(err.response.data.message, "error");
    }
  };

  const extractFile = async (filesRef, file) => {
    const extractedFiles = await JSZip.loadAsync(file);

    var index = 1;
    for await (let fileObj of Object.values(extractedFiles.files)) {
      if (fileObj.dir) continue;
      const zblob = await fileObj.async("blob");
      filesRef.current[`file${index++}`] = {
        file: new File([zblob], fileObj.name, {
          lastModified: fileObj.date.getTime(),
          type: getFileType(fileObj.name).type,
        }),
      };
    }
  };

  useEffect(async () => {
    if (!moduleId) return;
    setLoading(false);
    files.current = {};
    //set files
    if (thumbnail) files.current["thumbnail"] = { file: thumbnail };
    if (video) files.current["video"] = { file: video };
    if (audio) files.current["audio"] = { file: audio };
    if (file) {
      console.log(file);
      if (getFileType(file.name).type === "application/zip") {
        await extractFile(files, file);
      } else {
        files.current["file"] = { file: file };
      }
    }
    setUploader(true);
    //Open Uploader Component
  }, [moduleId]);

  return (
    <ModalWrapper>
      <ModalContainer>
        <Header>
          <h2>Add Modules</h2>
          <h2 onClick={closeModal} style={{ cursor: "pointer" }}>
            ×
          </h2>
        </Header>
        <AddModulesContainer>
          <div style={{ width: "48%" }}>
            <Input
              name="name"
              handleOnChange={(e) => setModuleName(e.target.value)}
              fontsize=".9rem"
              placeholder="Enter Modules"
              height="32px"
            />
          </div>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setThumbnail} fileType="image" />{" "}
            <span>{thumbnail ? thumbnail.name : "thumbnail"}</span>
          </div>
        </AddModulesContainer>
        <AddModulesContainer>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setVideo} fileType="video" />{" "}
            <span>{video ? video.name : "video"}</span>
          </div>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setFile} fileType="file" />
            <span>{file ? file.name : "file"}</span>
          </div>
        </AddModulesContainer>
        <AddModulesContainer>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setAudio} fileType="audio" />
            <span>{audio ? audio.name : "audio"}</span>
          </div>
        </AddModulesContainer>
        <ButtonWrapper>
          <Button onClick={closeModal}>CANCEL</Button>
          <Button onClick={() => createModule()}>Add</Button>
        </ButtonWrapper>
        {/*Update This Loader*/}
        {loading && (
          <DocumentLoading text="Please Wait your data is being uploaded" />
        )}
        {uploader && (
          <Uploader
            setModal={setUploader}
            files={files.current}
            moduleId={moduleId}
          />
        )}
      </ModalContainer>
    </ModalWrapper>
  );
};

export default ModuleUploadModal;
