import React, { useEffect, useState } from "react";
import {
  DashboardHeader,
  DashboardHeaderWrapper,
} from "../../../../../Dashboard/Dashboard.style";

import HeaderPage from "../../../../../Header/HeaderPage";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import InputComponent from "../../../../../Input/InputComponent";
import ProfileImg from "../../../../../../images/profileImg.svg";
import { Line } from "../../../../../Dashboard/Dashboard.style";
import axios from "axios";
import { useAuth } from "../../../../../../Auth/Auth";
import { resources } from "../../../../../../Api/api";
import { ResourcesWrapper } from "./Resources.style";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import TableComponent from "../../../../../Table/Table";
import { ResourcesTable } from "../../../../../Table/TableHeaders";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// import AddModules from "../../../../../Modal/AddModules";
//import AddResources from "../../../../../Modal/AddResources";

import AddResources from "../../../../../Modal/Module-v2/ResourceUpload";

function Resources() {
  const [switchLang, setSwitchLang] = useState(0);
  const navigation = useNavigate();
  const { readCookie } = useAuth();
  const { modulesId } = useParams();
  const [resource, setResources] = useState([]);
  const [modal, setModal] = useState(false);
  const [tabData, setTabData] = useState([]);
  const { state } = useLocation();
  const [render, setRender] = useState(false);
  const {
    id,
    classId,
    chapterId,
    subjectId,
    countryName,
    subjectName,
    chapterName,
    boardName,
    className,
    boardId,
    TopicName,
    TopicId,
  } = useParams();
  const leftHeader = () => {
    return (
      <div>
        <span>Courses</span> {` > `}
        <Link
          style={{ textDecoration: "none", color: "black" }}
          to={`/courses`}
        >
          <span>{countryName}</span>
        </Link>
        {` > `}
        <span
          onClick={() => sndBack("board")}
          style={{ textDecoration: "none", cursor: "pointer", color: "black" }}
        >
          {boardName}
        </span>
        {` > `}
        <span
          onClick={() => sndBack("board")}
          style={{ textDecoration: "none", cursor: "pointer", color: "black" }}
        >
          {className}
        </span>
        {` > `}
        <span
          onClick={() => sndBack("class")}
          style={{ textDecoration: "none", cursor: "pointer", color: "black" }}
        >
          {subjectName}
        </span>
        {` > `}
        <span
          onClick={() => sndBack("class")}
          style={{ textDecoration: "none", cursor: "pointer", color: "black" }}
        >
          {chapterName}
        </span>
        {` > `}
        <span
          onClick={() => sndBack("topic")}
          style={{ textDecoration: "none", cursor: "pointer", color: "black" }}
        >
          {TopicName}
        </span>
        {` > `}
        <span>Resource</span>
      </div>
    );
  };

  useEffect(() => {
    axios
      .get(resources.getResources(modulesId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setResources(res.data.data.module.resources);
      })
      .catch((err) => console.log(err));
  }, [render]);

  const deleteResource = (id) => {
    axios
      .delete(resources.deleteResources(modulesId, id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setResources(resource.filter((item) => item._id !== id));
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (resource.length) {
      let temp = resource?.map((item) => {
        return {
          _id: item._id,
          fileName: item.fileName.split("/").at(-1),
          fileUrl: (
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to={`/media?fileUrl=${item.fileUrl}`}
            >
              {item.fileUrl}
            </Link>
          ),
          del: <DeleteOutlineIcon onClick={() => deleteResource(item._id)} />,
        };
      });
      setTabData(temp);
    }
  }, [resource]);

  const sndBack = (type) => {
    let lang = state?.lang || "english";
    if (type === "board") {
      navigation(`/courses/${countryName}/${id}`, {
        state: {
          boardID: boardId,
        },
      });
    } else if (type === "class") {
      navigation(
        `/courses/${countryName}/${id}/${boardName}/${boardId}/${className}/${classId}`,
        {
          state: {
            subjectId: subjectId,
            lang: state?.lang || "english",
          },
        }
      );
    } else if (type === "topic") {
      navigation(
        `/courses/${countryName}/${id}/${boardName}/${boardId}/${className}/${classId}/${subjectName}/${subjectId}/${chapterName}/${chapterId}?trial=${
          switchLang === 1
        }`,
        {
          state: {
            lang: lang,
            trial: switchLang === 0 ? false : true,
          },
        }
      );
    }
  };

  return (
    <ResourcesWrapper>
      <DashboardHeaderWrapper minHeight="90px">
        <DashboardHeader>
          <div>
            <h1>Add Resources</h1>
          </div>

          <div>
            <InputComponent type="text" search placeholder="Search..." />
            <Link to="/profile">
              <img
                style={{
                  maxWidth: "100px",
                  height: "50px",
                  borderRadius: "50%",
                }}
                src={localStorage.getItem("imgUrl") || ProfileImg}
                alt="profileimg"
              />
            </Link>
          </div>
        </DashboardHeader>
        <DashboardHeader></DashboardHeader>
        <HeaderPage
          close={false}
          leftHeader={leftHeader(
            countryName,
            boardName,
            className,
            subjectName,
            chapterName,
            id,
            classId,
            chapterId,
            subjectId,
            TopicId,
            TopicName
          )}
        />
      </DashboardHeaderWrapper>
      <Line />
      <div
        style={{
          margin: "30px 0",
          verticalAlign: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <AddBoxOutlinedIcon
          onClick={() => setModal(true)}
          style={{ marginRight: "5px", cursor: "pointer" }}
        />{" "}
        <span style={{ cursor: "pointer" }} onClick={() => setModal(true)}>
          {" "}
          Add Resources{" "}
        </span>
      </div>
      <Line />
      <TableComponent tabHeader={ResourcesTable} tabContent={tabData} />
      {modal && (
        <AddResources
          setRender={setRender}
          render={render}
          resources={resource}
          setResources={(newResource) =>
            setResources([...resource, newResource])
          }
          topicId={state.topicId}
          moduleId={modulesId}
          setModal={setModal}
        />
      )}
    </ResourcesWrapper>
  );
}

export default Resources;
