import React, { useState, useRef } from "react";

import { ButtonWrapper, Button } from "../../Dashboard/Dashboard.style";
import { ModalWrapper, ModalContainer } from "../../Dashboard/Dashboard.style";
import { Header, AddModulesContainer } from "../Modal.style";

import DropzoneComponent from "../../Dropzone/DropzoneComponent";
import DocumentLoading from "../../Loading/ExportLoader";

import Uploader from "./Uploader";
import JSZip from "jszip";

const getFileType = (name) => {
  const file = {
    ext: name.split(".")[name.split(".").length - 1],
  };
  if (["jpg", "jpeg", "png", "gif"].includes(file.ext))
    file.type = `image/${file.ext}`;
  if (["mkv", "mp4", "m4v", "swf", "avi"].includes(file.ext))
    file.type = `video/${file.ext}`;
  if (["mp3"].includes(file.ext)) file.type = `audio/${file.ext}`;
  if (["otf", "woff", "woff2", "tff"].includes(file.ext))
    file.type = `font/${file.ext}`;
  if (["html", "css", "js"].includes(file.ext)) file.type = `text/${file.ext}`;
  if (!file.type) file.type = `application/${file.ext}`;
  return file;
};

function isUploaded(resources, filePath) {
  return resources.find((resource) => {
    try {
      const path = new URL(resource.fileUrl).pathname;
      console.log(path, filePath);
      return path === filePath;
    } catch (err) {
      return false;
    }
  });
}

const AddResources = (props) => {
  const { setModal, moduleId, setResources, resources } = props;

  const [loading, setLoading] = useState(false);
  const [uploader, setUploader] = useState(false);

  const files = useRef();

  const [thumbnail, setThumbnail] = useState("");
  const [video, setVideo] = useState("");
  const [file, setFile] = useState("");
  const [audio, setAudio] = useState("");

  const extractFile = async (filesRef, file) => {
    const extractedFiles = await JSZip.loadAsync(file);
    var index = 1;
    for await (let fileObj of Object.values(extractedFiles.files)) {
      if (fileObj.dir) continue;
      const zblob = await fileObj.async("blob");
      filesRef.current[`file${index++}`] = {
        status: isUploaded(resources, `/${moduleId}/${fileObj.name}`)
          ? "uploaded"
          : "pending",
        file: new File([zblob], fileObj.name, {
          lastModified: fileObj.date.getTime(),
          type: getFileType(fileObj.name).type,
        }),
      };
    }
  };

  const handleAdd = async () => {
    if (!moduleId) return;
    setLoading(false);
    files.current = {};
    //set files
    if (thumbnail)
      files.current["thumbnail"] = {
        status: isUploaded(resources, `/${moduleId}/${thumbnail.name}`)
          ? "uploaded"
          : "pending",
        file: thumbnail,
      };
    if (video)
      files.current["video"] = {
        status: isUploaded(resources, `/${moduleId}/${video.name}`)
          ? "uploaded"
          : "pending",
        file: video,
      };
    if (audio)
      files.current["audio"] = {
        status: isUploaded(resources, `/${moduleId}/${audio.name}`)
          ? "uploaded"
          : "pending",
        file: audio,
      };
    if (file) {
      if (getFileType(file.name).type === "application/zip") {
        await extractFile(files, file);
      } else {
        files.current["file"] = { status: "file", file };
      }
    }
    setUploader(true);
  };

  return (
    <ModalWrapper>
      <ModalContainer style={{ minWidth: "400px" }}>
        <Header>
          <h2>Add Resources</h2>
          <h2 onClick={() => setModal(false)} style={{ cursor: "pointer" }}>
            ×
          </h2>
        </Header>
        <AddModulesContainer>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setThumbnail} fileType="image" />{" "}
            <span>{thumbnail ? thumbnail.name : "thumbnail"}</span>
          </div>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setVideo} fileType="video" />{" "}
            <span>{video ? video.name : "video"}</span>
          </div>
        </AddModulesContainer>
        <AddModulesContainer>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setFile} fileType="file" />
            <span>{file ? file.name : "file"}</span>
          </div>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setAudio} fileType="audio" />
            <span>{audio ? audio.name : "audio"}</span>
          </div>
        </AddModulesContainer>

        <ButtonWrapper>
          <Button onClick={() => setModal(false)}>CANCEL</Button>
          <Button onClick={() => handleAdd()}>Add</Button>
        </ButtonWrapper>
        {/*Update This Loader*/}
        {loading && (
          <DocumentLoading text="Please Wait your data is being uploaded" />
        )}
        {uploader && (
          <Uploader
            setModal={setUploader}
            files={files.current}
            moduleId={moduleId}
            setResources={setResources}
            resourceLevel={true}
          />
        )}
      </ModalContainer>
    </ModalWrapper>
  );
};

export default AddResources;
